<template>
	<div>
		<div class="d-flex">
			<span class="display-1 mb-3">
				{{ user.name.full }}
			</span>
			<UserInfoForm :loading-parent="loading || loadingParent" />
			<v-spacer />
			<div class="d-flex flex-column">
				<span class="body-2 mx-2"> <v-icon>mdi-calendar</v-icon> {{ humanTime(user.seen.first) }} </span>
				<span class="body-2 mx-2"> <v-icon>mdi-update</v-icon> {{ humanTime(user.seen.last) }} </span>
			</div>
		</div>
		<div class="mt-1 d-flex justify-left">
			<span class="body-2 mr-3">
				<v-icon>mdi-email</v-icon> <a :href="'mailto:' + user.email" target="_blank">{{ user.email }}</a>
			</span>
			<span class="body-2 mx-3"> <v-icon>mdi-translate</v-icon> {{ user.locale.name }} </span>
		</div>
		<div class="mt-1 d-flex justify-left">
			<span class="body-2 mr-3">
				<v-icon :color="user.verified ? 'success' : 'error'">
					{{ user.verified ? 'mdi-check-circle' : 'mdi-close-circle' }}
				</v-icon>
				{{ user.verified ? $t('profile.verified') : $t('profile.unverified') }}
			</span>
			<span class="body-2 mr-3">
				<v-icon :color="user.promotionalEmails ? 'success' : 'error'">
					{{ user.promotionalEmails ? 'mdi-check-circle' : 'mdi-close-circle' }}
				</v-icon>
				{{ $t('users.promotionalEmails') }}
			</span>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'UserInfo',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	components: {
		UserInfoForm: () => import('@/components/users/UserInfoForm.vue')
	},
	computed: {
		...mapGetters({
			user: 'users/user'
		})
	},
	methods: {
		humanTime(timestamp) {
			return this.$moment(timestamp).format('YYYY-MM-DD hh:mm:ss')
		}
	}
}
</script>
